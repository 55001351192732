<template>
  <div class="mining">
    <!-- <Left></Left> -->
    <div class="right">
      <div class="conter">
        <el-tabs v-model="activeName" type="card" stretch>
          <el-tab-pane label="日报" name="first">
            <dailyPaper @getDetail="getDetail"></dailyPaper>
          </el-tab-pane>
          <el-tab-pane label="周报" name="second">
            <dailyPaper1 @getDetail="getDetail"></dailyPaper1>
          </el-tab-pane>
          <el-tab-pane label="月报" name="third">
            <dailyPaper2 @getDetail="getDetail"></dailyPaper2>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <el-dialog
      title="详情"
      :visible.sync="modifyRecordVisible"
      width="700px"
      center
      append-to-body
      style="min-width: 800px"
    >
      <el-table
        :data="modifyRecordDetail"
        height="400px"
        style="width: 100%"
        ref="RecordDetail"
      >
        <el-table-column label="修改时间" width="120px" key="createTime">
          <template slot-scope="{ row }">
            {{ row.createTime }}
          </template>
        </el-table-column>
        <el-table-column label="时间" width="120px" key="dailyDate">
          <template slot-scope="{ row }">
            {{ row.modifyContent.dailyDate }}
          </template>
        </el-table-column>
        <el-table-column
          label="当日产量(万吨)"
          width="120px"
          v-if="activeName == 'first'"
          key="dailyProduct"
        >
          <template slot-scope="{ row }">
            {{ row.modifyContent.dailyProduct }}
          </template>
        </el-table-column>
        <el-table-column
          label="当日销量(万吨)"
          width="120px"
          v-if="activeName == 'first'"
          key="dailySales"
        >
          <template slot-scope="{ row }">
            {{ row.modifyContent.dailySales }}
          </template>
        </el-table-column>
        <el-table-column
          label="库存情况(万吨)"
          width="120px"
          v-if="activeName == 'first'"
          key="stock"
        >
          <template slot-scope="{ row }">
            {{ row.modifyContent.stock }}
          </template>
        </el-table-column>
        <el-table-column
          label="当日价格(元/吨)"
          width="120px"
          v-if="activeName == 'first'"
          key="dailyPrice"
        >
          <template slot-scope="{ row }">
            {{ row.modifyContent.dailyPrice }}
          </template>
        </el-table-column>
        <el-table-column
          label="本周产量(万吨)"
          width="120px"
          v-if="activeName == 'second'"
          key="rawWeekProduct"
        >
          <template slot-scope="{ row }">
            {{ row.modifyContent.rawWeekProduct }}
          </template>
        </el-table-column>
        <el-table-column
          label="累计产量(万吨)"
          width="120px"
          v-if="activeName == 'second'"
          key="rawProductCount"
        >
          <template slot-scope="{ row }">
            {{ row.modifyContent.rawProductCount }}
          </template>
        </el-table-column>
        <el-table-column
          label="本周销量(万吨)"
          width="120px"
          v-if="activeName == 'second'"
          key="marketWeekProduct"
        >
          <template slot-scope="{ row }">
            {{ row.modifyContent.marketWeekProduct }}
          </template>
        </el-table-column>
        <el-table-column
          label="累计销量(万吨)"
          width="120px"
          v-if="activeName == 'second'"
          key="marketProductCount"
        >
          <template slot-scope="{ row }">
            {{ row.modifyContent.marketProductCount }}
          </template>
        </el-table-column>
        <el-table-column
          label="本地销量(万吨)"
          width="120px"
          v-if="activeName == 'second'"
          key="localityMarketProduct"
        >
          <template slot-scope="{ row }">
            {{ row.modifyContent.localityMarketProduct }}
          </template>
        </el-table-column>
        <el-table-column
          label="本月产量(万吨)"
          width="120px"
          v-if="activeName == 'third'"
          key="rawMonthProduct"
        >
          <template slot-scope="{ row }">
            {{ row.modifyContent.rawMonthProduct }}
          </template>
        </el-table-column>
        <el-table-column
          label="本月销量(万吨)"
          width="120px"
          v-if="activeName == 'third'"
          key="marketMonthProduct"
        >
          <template slot-scope="{ row }">
            {{ row.modifyContent.marketMonthProduct }}
          </template>
        </el-table-column>
        <el-table-column
          label="月末库存(万吨)"
          width="120px"
          v-if="activeName == 'third'"
          key="inventory"
        >
          <template slot-scope="{ row }">
            {{ row.modifyContent.inventory }}
          </template>
        </el-table-column>
        <el-table-column
          label="平均煤价(万吨)"
          width="120px"
          v-if="activeName == 'third'"
          key="averagePrice"
        >
          <template slot-scope="{ row }">
            {{ row.modifyContent.averagePrice }}
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="主要外运方向"
          width="220px"
          v-if="activeName == 'second' || activeName == 'third'"
          key="highway"
        >
          <template
            slot-scope="{ row }"
            v-if="row.modifyContent?.highway?.length"
          >
            公路：<span
              v-for="(item, index) in row.modifyContent.highway"
              :key="index"
              >{{ item.directionName }}{{ item.total }}万吨,</span
            >
            <br />
            铁路：<span
              v-for="(item, index) in row.modifyContent.railway"
              :key="index"
              >{{ item.directionName }}{{ item.total }}万吨,</span
            >
          </template>
        </el-table-column> -->
        <el-table-column label="状态" key="auditStatus">
          <template slot-scope="{ row }">
            <span v-if="row.auditStatus === null">正常</span>
            <span v-if="row.auditStatus == 0">待审核</span>
            <span v-if="row.auditStatus == 1">审核通过</span>
            <span v-if="row.auditStatus == 2">审核拒绝</span>
          </template>
        </el-table-column>
        <el-table-column
          label="原因"
          prop="auditReason"
          show-overflow-tooltip
          key="auditReason"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          fixed="right"
          v-if="activeName == 'second' || activeName == 'third'"
          key="handle"
        >
          <template slot-scope="{ row }">
            <el-button type="text" @click="handleDetailView(row.modifyContent)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
        title=""
        append-to-body
        :visible.sync="dialogVisible"
        width="800px"
      >
        <div>
          <div
            style="
              border-bottom: 1px solid #ccc;
              padding-bottom: 10px;
              text-align: center;
            "
          >
            <div style="font-size: 19px">周报数据录入</div>
          </div>
          <div style="margin-top: 10px">
            <el-form
              :model="ruleForm"
              ref="ruleForm"
              label-width="130px"
              class="demo-ruleForm"
            >
              <div style="display: flex">
                <el-form-item label="日期" prop="value">
                  <el-date-picker
                    v-model="ruleForm.value"
                    disabled
                    type="week"
                    format="yyyy 第 WW 周"
                    placeholder="选择周"
                    :picker-options="{ firstDayOfWeek: 5 }"
                  >
                    <!-- @input="cc" -->
                  </el-date-picker>
                </el-form-item>
              </div>
              <div
                style="padding: 10px 0; text-align: center; background: #f6f6f6"
              >
                原煤产量
              </div>
              <div class="flexs" style="margin-top: 20px">
                <el-form-item label="本周产量(万吨)" prop="rawWeekProduct">
                  <el-input
                    disabled
                    v-model="ruleForm.rawWeekProduct"
                    placeholder="请输入本周产量"
                  ></el-input>
                </el-form-item>
                <el-form-item label="累计产量(万吨)" prop="rawProductCount">
                  <el-input
                    disabled
                    v-model="ruleForm.rawProductCount"
                    placeholder="请输入累计产量"
                  ></el-input>
                </el-form-item>
              </div>

              <div
                style="padding: 10px 0; text-align: center; background: #f6f6f6"
              >
                煤炭销量
              </div>
              <div class="flexs" style="margin-top: 20px">
                <el-form-item label="本周销量(万吨)" prop="marketWeekProduct">
                  <el-input
                    disabled
                    v-model="ruleForm.marketWeekProduct"
                    placeholder="请输入煤炭销量"
                  ></el-input>
                </el-form-item>
                <el-form-item label="累计销量(万吨)" prop="marketProductCount">
                  <el-input
                    disabled
                    v-model="ruleForm.marketProductCount"
                    placeholder="请输入累计销量"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="本地销量(万吨)"
                  prop="localityMarketProduct"
                >
                  <el-input
                    disabled
                    v-model="ruleForm.localityMarketProduct"
                    placeholder="请输入本地销量"
                  ></el-input>
                </el-form-item>
              </div>
              <div
                style="padding: 10px 0; text-align: center; background: #f6f6f6"
              >
                外调煤
              </div>
              <div class="flexs" style="margin-top: 20px">
                <div>
                  <span style="font-size: 18px; font-weight: bold; color: black"
                    >铁路</span
                  >
                </div>
              </div>
              <div
                class="flexs"
                style="margin-top: 20px"
                v-for="(items, index) in list1"
                :key="index"
              >
                <el-form-item label="方向" prop="directionId">
                  <el-cascader
                    v-model="items.directionId"
                    ref="cascaderItem"
                    :options="citys"
                    style="width: 300px"
                    placeholder="请选择方向"
                    :props="{
                      value: 'id',
                      label: 'name',
                      checkStrictly: true,
                    }"
                    disabled
                  ></el-cascader>
                </el-form-item>
                <el-form-item label="本周运量(万吨)" prop="total">
                  <el-input
                    disabled
                    v-model="items.total"
                    placeholder="请输入本周运量"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="flexs" style="margin-top: 20px">
                <div>
                  <span style="font-size: 18px; font-weight: bold; color: black"
                    >公路</span
                  >
                </div>
              </div>
              <div
                class="flexs"
                style="margin-top: 20px"
                v-for="(item, index) in list"
                :key="index"
              >
                <el-form-item label="方向" prop="directionId">
                  <el-cascader
                    v-model="item.directionId"
                    :options="citys"
                    style="width: 300px"
                    disabled
                    placeholder="请选择方向"
                    :props="{
                      value: 'id',
                      label: 'name',
                      checkStrictly: true,
                    }"
                  ></el-cascader>
                </el-form-item>
                <el-form-item label="本周运量(万吨)" prop="total">
                  <el-input
                    disabled
                    v-model="item.total"
                    placeholder="请输入本周运量"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        title=""
        destroy-on-close
        append-to-body
        :visible.sync="dialogVisible1"
        width="800px"
      >
        <div>
          <div
            style="
              border-bottom: 1px solid #ccc;
              padding-bottom: 10px;
              text-align: center;
            "
          >
            <div style="font-size: 19px">月报数据录入</div>
          </div>
          <div style="margin-top: 10px">
            <el-form
              :model="ruleForm"
              ref="ruleForm"
              label-width="130px"
              class="demo-ruleForm"
            >
              <div style="display: flex">
                <el-form-item label="日期" prop="declareDate">
                  <el-date-picker
                    v-model="ruleForm.dailyDate"
                    disabled
                    type="month"
                    placeholder="选择月"
                    value-format="yyyy-MM"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="本月产量(万吨)" prop="rawMonthProduct">
                  <el-input
                    disabled
                    v-model="ruleForm.rawMonthProduct"
                    placeholder="请输入本月产量"
                  ></el-input>
                </el-form-item>
              </div>
              <div style="display: flex">
                <el-form-item label="本月销量(万吨)" prop="marketMonthProduct">
                  <el-input
                    disabled
                    v-model="ruleForm.marketMonthProduct"
                    placeholder="请输入本月销量"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="月末库存(万吨)"
                  prop="inventory"
                  style="margin-left: 25px"
                >
                  <el-input
                    disabled
                    v-model="ruleForm.inventory"
                    placeholder="请输入月末库存"
                  ></el-input>
                </el-form-item>
              </div>

              <div style="display: flex">
                <el-form-item label="平均煤价(元/吨)" prop="averagePrice">
                  <el-input
                    disabled
                    v-model="ruleForm.averagePrice"
                    placeholder="请输入平均煤价"
                  ></el-input>
                </el-form-item>
                <el-form-item label=""> </el-form-item>
              </div>
              <div
                style="padding: 10px 0; text-align: center; background: #f6f6f6"
              >
                外调煤
              </div>
              <div class="flexs" style="margin-top: 20px">
                <div>
                  <span style="font-size: 18px; font-weight: bold; color: black"
                    >铁路</span
                  >
                </div>
              </div>
              <div
                class="flexs"
                style="margin-top: 20px"
                v-for="(items, index) in railway"
                :key="index"
              >
                <el-form-item label="方向" prop="directionId">
                  <el-cascader
                    v-model="items.directionId"
                    :options="citys"
                    style="width: 300px"
                    disabled
                    placeholder="请选择方向"
                    :props="{
                      value: 'id',
                      label: 'name',
                      checkStrictly: true,
                    }"
                  ></el-cascader>
                </el-form-item>
                <el-form-item label="本月运量(万吨)" prop="total">
                  <el-input
                    disabled
                    v-model="items.total"
                    placeholder="请输入本月运量"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="flexs" style="margin-top: 20px">
                <div>
                  <span style="font-size: 18px; font-weight: bold; color: black"
                    >公路</span
                  >
                </div>
              </div>
              <div
                class="flexs"
                style="margin-top: 20px"
                v-for="(item, index) in highway"
                :key="index"
              >
                <el-form-item label="方向" prop="directionId">
                  <el-cascader
                    v-model="item.directionId"
                    :options="citys"
                    disabled
                    style="width: 300px"
                    placeholder="请选择方向"
                    :props="{
                      value: 'id',
                      label: 'name',
                      checkStrictly: true,
                    }"
                  ></el-cascader>
                </el-form-item>
                <el-form-item label="本月运量(万吨)" prop="total">
                  <el-input
                    disabled
                    v-model="item.total"
                    placeholder="请输入本月运量"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import * as types from "@/store/action-types";
import { modifyRecordList, modifyAudit } from "@/api/public";
import Vue from "vue";
import citys from "@/assets/json/citys";
import { getTreeV2 } from "@/api/Medium.js";
export default {
  data() {
    return {
      activeName: "first",
      modifyRecordDetail: [],
      modifyRecordVisible: false,
      rowId: "",
      isRefuse: false,
      refuseText: "",
      currentId: "",

      dialogVisible: false,
      dialogVisible1: false,

      citys: citys,
      ruleForm: {
        value: "",
      },
      list: [],
      list1: [],

      highway: [],
      railway: [],
    };
  },
  components: {
    Left: () => import("./components/left.vue"),
    dailyPaper: () => import("./mingConponents/dailyPaper.vue"),
    dailyPaper1: () => import("./mingConponents/dailyPaper1.vue"),
    dailyPaper2: () => import("./mingConponents/dailyPaper2.vue"),
  },
  created() {
    // let routerArr = ['/manage', '/myAssets', '/jiaoyi', '/finance','/Mining', '/released', '/participation', '/purchase', '/take', '/purchasehangtag', '/markethangtag', '/order', '/personnelStructure', '/record', '/guanzhu', '/qianzhang', '/contract', '/myPublished', '/financeTax', '/banking', '/logistics', '/warehouse', '/science', '/usmyPublished', '/usfinanceTax', '/usbanking', '/uslogistics', '/uswarehouse', '/usscience']
    // for (let i = 0; i < routerArr.length; i++) {
    //   if (this.$route.path == routerArr[i]) {
    //     Vue.ls.set("active", i + 1);
    //     console.log( Vue.ls.get('active'))
    //   }
    // }
  },
  mounted() {
    this.getTree();
  },
  methods: {
    deleteChildren(arr) {
      let childs = arr;
      for (let i = childs.length; i--; i > 0) {
        if (childs[i].children) {
          let newlist = childs[i].children;
          for (let j = newlist.length; j--; j > 0) {
            delete newlist[j].children;
          }
        }
      }
      return arr;
    },
    getTree() {
      getTreeV2().then((res) => {
        if (res.code == 0) {
          this.citys = this.deleteChildren(res.data);
        }
      });
    },
    handleDetailView(e) {
      if (this.activeName == "second") {
        this.ruleForm = {};
        this.list1 = e.railway.map((item) => {
          item.directionId = item.directionId.toString();
          return item;
        });
        this.list = e.highway.map((item) => {
          item.directionId = item.directionId.toString();
          return item;
        });
        e.value = e.declareStartDate;
        this.ruleForm = JSON.parse(JSON.stringify(e));
        this.$nextTick(() => {
          this.dialogVisible = true;
        });
      }
      if (this.activeName == "third") {
        this.ruleForm = {};
        this.railway = e.railway.map((item) => {
          item.directionId = item.directionId.toString();
          return item;
        });
        this.highway = e.highway.map((item) => {
          item.directionId = item.directionId.toString();
          return item;
        });
        this.ruleForm = e;
        console.log(this.ruleForm)
        this.$nextTick(() => {
          this.dialogVisible1 = true;
        });
      }
    },
    formatDate(val, type) {
      if (!val) {
        return "";
      }
      let date = new Date(val);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      if (type == "week") {
        return y + "-" + m + "-" + d;
      }
      if (type == "month") {
        return y + "-" + m;
      }
    },
    getDetail(id) {
      this.rowId = id;
      modifyRecordList({ id: id }).then((res) => {
        if (res.code == 0) {
          this.modifyRecordDetail = res.data.map((item) => {
            item.modifyContent = JSON.parse(item.modifyContent);
            if (this.activeName == "second") {
              item.modifyContent.dailyDate = `${this.formatDate(
                item.modifyContent.declareStartDate,
                "week"
              )}~${this.formatDate(item.modifyContent.declareEndDate, "week")}`;
            }
            if (this.activeName == "third") {
              item.modifyContent.dailyDate = this.formatDate(
                item.modifyContent.declareDate,
                "month"
              );
            }
            return item;
          });
          this.$nextTick(() => {
            this.modifyRecordVisible = true;
            this.$nextTick(() => {
              this.$refs.RecordDetail.doLayout();
            });
          });
        } else {
          this.modifyRecordDetail = [];
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.secondImg {
  text-align: center;
}

.siz {
  font-size: 20px;
  font-weight: bold;
}

.secondImg img {
  width: 50%;
}

.mining {
  width: 1196px;
  margin: 0 auto;
  display: flex;

  .right {
    width: 970px;
    font-size: 14px;

    .conter {
      width: 100%;
      border: 1px solid #ccc;
      background: #fff;
      /* padding: 20px; */
      margin-top: 20px;
    }
  }
}

.addcontent {
  // width: 90%;
  margin: 0 auto;
  padding: 20px 20px 0 20px;
}

.line {
  width: 100%;
  height: 2px;
  margin: 0 auto;
  background: #ccc;
}

.flex {
  display: flex;
}

.title {
  text-align: center;
  font-size: 24px;
  margin: 20px 0;
}

.tableBorder {
  width: 90%;
  margin: 0 auto;
}

::v-deep .el-tabs__content {
  min-height: 580px;
  padding-bottom: 20px;
  // background-color: #4cb2fc;s
}

::v-deep .el-tabs__item {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 800;
  // color: #0294ff;
  line-height: 60px;
  height: 60px;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 228px;
}

.dailyPaper {
  padding: 20px;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 23px;
}

.content {
  margin-top: 20px;
}

.cont_title {
  font-size: 23px;
  text-align: left;
}

.flexs {
  display: flex;
  justify-content: space-between;
}

.lines {
  width: 100%;
  height: 1px;
  background: #ccc;
  margin: 10px 0;
}
</style>
